import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchLanguagesData } from '../../../service/languages';

const CreateCourses = () => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [courseName, setCourseName] = useState('');

  useEffect(() => {
    sessionStorage.clear()
    getLanguages();
  }, []);

  const getLanguages = async () => {
    try {
      const languagesData = await fetchLanguagesData();
      if (languagesData.status) {
        setLanguages(languagesData.data);
      } else {
        console.error("Error fetching languages:", languagesData.error);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const handleLanguageChange = (selectedOptions) => {
    setSelectedLanguages(selectedOptions);
    sessionStorage.setItem("selectedLanguages",JSON.stringify(selectedOptions))
  };

  const handleCourseNameChange = (courseName) => {
    setCourseName(courseName.target.value);
    sessionStorage.setItem("courseName",courseName.target.value)
  };

  const languageOptions = languages.map((lang) => ({
    value: lang._id,
    label: lang.name,
  }));

  return (
    <div className='d-flex justify-content-center mt-100'>
      <div className='w-35'>
        <form>
          <div className="form-group">
            <label htmlFor="name">Enter Course Name</label>
            <input type="text" className="form-control mt-2" id="name" value={courseName} onChange={handleCourseNameChange} />
          </div>

          <div className="form-group mt-70">
            <label className='mb-2'>Select Languages</label>
            <Select
              isMulti
              options={languageOptions}
              value={selectedLanguages}
              onChange={handleLanguageChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCourses;
