// axiosConfig.js

import axios from 'axios';
import apiService from '../apiService';


const axiosInstance = axios.create({
  baseURL: 'https://api.example.com', // Replace with your API base URL
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json'
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config, such as adding headers or handling authentication
    console.log('Request Interceptor:', config);
    return config;
  },
  (error) => {
    // Handle request error
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data
    // console.log('Response Interceptor:', response);
    return response;
  },
  (error) => {
    // Handle response error
    console.error('Response Interceptor Error:', error);

    // Customize error handling based on your needs
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response Status:', error.response.status);
      console.error('Response Data:', error.response.data);
      if (error.response.data.message === "Invalid token id!") {
        // Redirect the user to the login page
        window.location.href = '/';
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up the request:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
