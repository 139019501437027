// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course_wrapper {
    position: relative;
    height: 100vh;
}

.navbar {
    border: 1px solid rgba(51, 22, 22, 0.93);
    background: #F5F5F5 !important;
    height: 100px;
    top: 0;
    position: fixed !important;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 9999;
}

.p-40 {
    padding: 39px;
}

.text-decoration-none {
    text-decoration: none;
}

ul {
    list-style-type: none;
}

/* li {
    float: left;
} */

li a {
    text-decoration: none;
    color: #9B9B9B;
}

li .active{
    color: #146B90;
}

.menu_vertical {
    border-left: 1px solid #000000;
    margin: 0px 24px;
    height: 1.25rem;
}

.main {
    padding-top: 100px;
} 

.cursor_pointer {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/public.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,wCAAwC;IACxC,8BAA8B;IAC9B,aAAa;IACb,MAAM;IACN,0BAA0B;IAC1B,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;;GAEG;;AAEH;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".course_wrapper {\n    position: relative;\n    height: 100vh;\n}\n\n.navbar {\n    border: 1px solid rgba(51, 22, 22, 0.93);\n    background: #F5F5F5 !important;\n    height: 100px;\n    top: 0;\n    position: fixed !important;\n    left: 0;\n    right: 0;\n    bottom: auto;\n    z-index: 9999;\n}\n\n.p-40 {\n    padding: 39px;\n}\n\n.text-decoration-none {\n    text-decoration: none;\n}\n\nul {\n    list-style-type: none;\n}\n\n/* li {\n    float: left;\n} */\n\nli a {\n    text-decoration: none;\n    color: #9B9B9B;\n}\n\nli .active{\n    color: #146B90;\n}\n\n.menu_vertical {\n    border-left: 1px solid #000000;\n    margin: 0px 24px;\n    height: 1.25rem;\n}\n\n.main {\n    padding-top: 100px;\n} \n\n.cursor_pointer {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
