import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./courseDetails.css"
import { fetchCourseDetail } from '../../../service/course';
import apiFunctions from '../../../service/axiosConfig/apiFunctions';
import { baseUrl } from '../../../constants';
import CustomToast from '../../Toast';

const CourseDetails = () => {
    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); const [selectedFileB, setSelectedFileB] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null); const [previewUrlB, setPreviewUrlB] = useState(null);
    const [fileP_path, setFileP_path] = useState('')
    const [fileP_pathB, setFileP_pathB] = useState('')
    const [fileV_path, setFileV_path] = useState('')
    const [selectedVFile, setSelectedVFile] = useState(null);
    const [previewVUrl, setPreviewVUrl] = useState(null);
    const [previewDataVUrl, setPreviewDataVUrl] = useState(null);
    const [Upload, setUpload] = useState(false)
    const [UploadB, setUploadB] = useState(false)
    const [UploadV, setUploadV] = useState(false)
    const [courseName,setCourseName] = useState('')
    const [courseID,setCourseId] = useState('')

    const [courseData, setCourseData] = useState({
        courseTitle: '',
        courseTagline: '',
        duration: '',
        skillset: '',
        editorContent: '',
        additionalEditors: ['']
    });
    const handleFileChangeB = (event) => {
        const file = event.target.files[0];
        setSelectedFileB(file);
        setUploadB(true)
        // Show preview
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrlB(reader.result);
            };
            reader.readAsDataURL(file);
            // handleImageUpload()
        } else {
            setPreviewUrlB(null);
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setUpload(true)
        // Show preview
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            // handleImageUpload()
        } else {
            setPreviewUrl(null);
        }
    };

    const handleImageUpload = async () => {
        if (!selectedFile) {
            // alert('Please select an image');
            CustomToast.info('Please select an image')
            return;
        }

        try {
            // Calling the new function to upload the image
            const result = await apiFunctions.uploadImage(`${baseUrl}uploadImage`, selectedFile);
            setFileP_path(result.data.file_path)
            sessionStorage.setItem('imageData', JSON.stringify(result.data.file_path))
            setUpload(false)
            CustomToast.success('File uploaded successfully')
        } catch (error) {
            console.error('Image upload error:', error);
            CustomToast.error('Error while uploading file')
        }
    };
    const handleImageUploadB = async () => {
        if (!selectedFileB) {
            // alert('Please select an image');
            CustomToast.info('Please select an image')
            return;
        }

        try {
            // Calling the new function to upload the image
            const result = await apiFunctions.uploadImage(`${baseUrl}uploadImage`, selectedFileB);
            setFileP_pathB(result.data.file_path)
            sessionStorage.setItem('imageDataB', JSON.stringify(result.data.file_path))
            setUploadB(false)
            CustomToast.success('File uploaded successfully')
        } catch (error) {
            console.error('Image upload error:', error);
            CustomToast.error('Error while uploading file')
        }
    };

    const handleFileVChange = (event) => {
        const file = event.target.files[0];

        setSelectedVFile(file);
        setPreviewDataVUrl('')
        setUploadV(true)
        // Show preview
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewVUrl(reader.result);

            };
            reader.readAsArrayBuffer(file);
            // handleVideoUpload()
        } else {
            setPreviewVUrl(null);
        }
    };

    const handleVideoUpload = async () => {
        if (!selectedVFile) {
            // alert('Please select a video');
            CustomToast.info('Please select an video to upload')
            return;
        }

        try {
            const result = await apiFunctions.uploadVideo(`${baseUrl}uploadVideo`, selectedVFile);
            setUploadV(false)
            setFileV_path(result.data.file_path)
            sessionStorage.setItem('videoData', JSON.stringify(result.data.file_path))
            CustomToast.success('File uploaded successfully')
        } catch (error) {
            console.error('Video upload error:', error);
            CustomToast.error('Error while uploading file')
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCourseDetail = sessionStorage.getItem("courseId");
            let courseName = sessionStorage.getItem("courseName")
            setCourseName(courseName)
           
            if (JSON.stringify(newCourseDetail) !== JSON.stringify(courseID)) {
                console.log(newCourseDetail,JSON.stringify(courseID))
                getCourseDetail(newCourseDetail)
                setCourseId(newCourseDetail)
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [courseID]);

    const getCourseDetail = (courseId) => {
        fetchCourseDetail(courseId).then((data) => {
            sessionStorage.setItem("courseDetail", JSON.stringify(data.data))
            if (data.status) {
                setCourseData({
                    duration:data.data.duration,
                    courseTitle: data.data.title,
                    courseTagline: data.data.tagline,
                    skillset: data.data.skillset,
                    additionalEditors: data?.data?.contents?.length > 0 ? data?.data?.contents : ['']
                })
                setPreviewUrl(data.data.image)
                setPreviewUrlB(data.data.banner_image)
                setPreviewDataVUrl(data.data.video)
            }
        }).catch((error) => {
            console.log("error:", error)
        })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCourseData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleAddEditor = () => {
        setCourseData((prevData) => ({
            ...prevData,
            additionalEditors: [...prevData.additionalEditors, '']
        }));
    };

    const handleAdditionalEditorChange = (editorIndex, data) => {
        setCourseData((prevData) => {
            const updatedEditors = [...prevData.additionalEditors];
            updatedEditors[editorIndex] = data;
            return {
                ...prevData,
                additionalEditors: updatedEditors
            };
        });
    };
    const handleRemoveEditor = (index) => {
        setCourseData((prevData) => {
            const updatedAdditionalEditors = [...prevData.additionalEditors];
            updatedAdditionalEditors.splice(index, 1);
            return {
                ...prevData,
                additionalEditors: updatedAdditionalEditors
            };
        });
    };

    useEffect(() => {
        sessionStorage.setItem('courseData', JSON.stringify(courseData))
    }, [courseData, previewUrl, previewUrl])

    return (
        <>
            <aside className={`course_details_sidebar sidebar px-4 pt-5 ${show ? "review" : " "} `} id="admin-dash-nav">
                <div className='d-flex flex-column justify-content-between h-100 p-3 course_details'>
                    <div className='row'> 
                      
                        <h5 className='font-20 mb-4'>Course Thumbnail</h5>

                        <input type="file" accept='image/*' onChange={handleFileChange} />

                        {Upload ? <button type='button' className='mt-4 w-50' onClick={handleImageUpload}>Upload Image</button> : null}
                        {previewUrl ? (
                            <div>
                                <img
                                    src={previewUrl}
                                    alt="Selected File Preview"
                                    className='mb-4'
                                    style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                                /></div>
                        ) : <img src="/images/image.png" className='my-4'></img>}


                        <h5 className='font-20 mb-4'>Course Banner</h5>

                        <input type="file" accept='image/*' onChange={handleFileChangeB} />

                        {UploadB ? <button type='button' className='mt-4 w-50' onClick={handleImageUploadB}>Upload Image</button> : null}
                        {previewUrlB ? (
                            <div>
                                <img
                                    src={previewUrlB}
                                    alt="Selected File Preview"
                                    className='mb-4'
                                    style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
                                /></div>
                        ) : <img src="/images/image.png" className='my-4'></img>}

                        <h5 className='font-20 mb-4'>Promo Video</h5>

                        <input type="file" accept="video/*" className='mb-4' onChange={handleFileVChange} />

                        {previewVUrl ? (<div>
                            {UploadV ? <button type='button' onClick={handleVideoUpload}>Upload Video</button>
                                : null}
                            <video
                                className='w-100'
                                controls
                                style={{ marginTop: '10px' }}
                            >
                                <source src={URL.createObjectURL(selectedVFile)} type={selectedVFile.type} />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        ) : previewDataVUrl ? (
                            <video
                                className='w-100'
                                controls
                                style={{ marginTop: '10px' }}
                            >
                                <source src={previewDataVUrl} type={previewDataVUrl.type} />
                                Your browser does not support the video tag.
                            </video>
                        ) :
                            <img src="/images/image.png"></img>}
                         </div>
                 </div>
            </aside>
            <div className='course_main'>

                <form>
                    <div className='row mb-4'>
                    <div className="col-md-6">
                            <label for="courseTitle" className="form-label">{courseName}</label>
                        </div>
                        </div>
                        <div className='row mb-4'>
                        <div className="col-md-6">
                            <label for="courseTitle" className="form-label">Course Title</label>
                            <input type="text" className="form-control" id="courseTitle" placeholder="Safe Workplaces" name="courseTitle" value={courseData.courseTitle}
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-6">
                            <label for="courseTagline" className="form-label">Course Tagline</label>
                            <input type="text" className="form-control" id="courseTagline" placeholder="Prevention against Sexual Harassment at Workplace" name="courseTagline" value={courseData.courseTagline}
                                onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className="col-md-6">
                            <label for="course_title" className="form-label">Estimate Duration</label>
                            <input type="text" className="form-control" id="course_title" placeholder="60 Mins" name="duration"  value={courseData.duration}  onChange={handleInputChange}/>
                        </div>
                        <div className="col-md-6">
                            <label for="skillset" className="form-label">Skillset</label>
                            <input type="text" className="form-control" id="skillset" placeholder="Use comma to separate skills" name="skillset" value={courseData.skillset}
                                onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-12">
                            <label className='mb-2'>Card Text</label>
                            {courseData.additionalEditors.map((editorContent, index) => (
                                <div key={index} className='mb-4 ckeditor'>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        // config={ editorConfiguration }
                                        data={editorContent}
                                        onChange={(event, editor) => handleAdditionalEditorChange(index, editor.getData())}
                                    />
                                    <button type="button" onClick={() => handleRemoveEditor(index)} className='delete-block'><img src='/images/delete.svg' alt='delete block' /></button>
                                    {/* <button type="button" onClick={() => handleRemoveEditor(index)}>Remove Block</button> */}
                                </div>
                            ))}
                            <button type="button" className='add-block' onClick={handleAddEditor}><span>Add Block</span></button>

                        </div>


                    </div>


                </form>

            </div>
        </>
    )

}

export default CourseDetails;
