import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicRoutesLayout from "./components/shared/publicRoutesLayout";
import DashboardLayout from "./components/shared/dashboardLayout";
import WithoutPrivateLayout from "./components/shared/withoutSidebarLayout";
import Dashboard from "./components/pages/dashboard/dashboard";
import Courses from "./components/pages/courses/courses";
import CreateCourses from "./components/pages/courses/createCourse";
import CourseLayout from "./components/shared/courseLayout";
import CourseDetails from "./components/pages/courseDetails/courseDetails";
import CourseContent from "./components/pages/courseContent/courseContent";
import Login from "./components/login/login";


export default function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={<PublicRoutesLayout />}
                >  <Route path='/' element={<Login />}></Route>
                </Route>

                <Route
                    path="/"
                    element={<DashboardLayout />}
                >

                    <Route path='/' element={<Courses />}></Route>
                    <Route path='/courses' element={<Courses />}></Route>
                    <Route path='/dashboard' element={<Dashboard />}></Route>
                </Route>

                <Route
                    path="/"
                    element={<WithoutPrivateLayout />}
                >
                    <Route path='/create_course' element={<CreateCourses />}></Route>
                </Route>

                <Route
                    path="/"
                    element={<CourseLayout />}
                >
                    <Route path='/course_details' element={<CourseDetails />}></Route>
                    <Route path="/course_content" element={<CourseContent />}></Route>
                </Route>
            </Routes>
        </Router>
    );
}

