import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../layouts/navbar'

const DashboardLayout = ({ children }) => {
  return (
    <>
      <Navbar children={children}>
        <Outlet />
      </Navbar>
    </>
  )
}

export default DashboardLayout