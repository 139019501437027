
import { baseUrl } from "../constants";
import apiFunctions from "./axiosConfig/apiFunctions";
import CustomToast from "../components/Toast";

 export const fetchCourseData = async (searchtext) => {
    try {
      // GET request
      searchtext=searchtext?.length>1?searchtext:'';
      const responseGet = await apiFunctions.fetchData(`${baseUrl}getCourseList?pageNumber=1&limit=100&search=${searchtext}`);
      return responseGet;
    } catch (error) {
      CustomToast.error('Error while fetcing course data')
        return error;
    } 
  };

  export const saveCourseWithLang = async (postData) => {
    try {
      // POST request
      const responseGet = await apiFunctions.postData(`${baseUrl}createMultiCourse`,postData);
      sessionStorage.setItem("courseId",responseGet.data?.courses[0]?.course_meta_id)
      CustomToast.success('Course data saved successfully')
      return responseGet;
    } catch (error) {
        CustomToast.error('Error while save course data')
        return error;
    } 
  };
  
  export const fetchCourseDetail = async (id) => {
    try {
      // GET request
      const responseGet = await apiFunctions.fetchData(`${baseUrl}getCourseDetail/${id}`);
      await sessionStorage.setItem("courseDetail",JSON.stringify(responseGet.data))
      return responseGet;
    } catch (error) {
      CustomToast.error('Error while fetcing course data')
        return error;
    } 
  };

  export const updateCourseDetail = async (type, id, putData) => {
    try {
      // PUT request
      const responseGet = await apiFunctions.putData(`${baseUrl}updateCourse/${id}`,putData);
      sessionStorage.setItem("courseDetail",JSON.stringify(responseGet.data));
      if(responseGet.data.is_published == false && type == "Draft"){
        CustomToast.success('Course draft successfully')
      }else if(responseGet.data.is_published == true && type == "Publish"){
        CustomToast.success('Course published successfully')
      } else {
        CustomToast.success('Data save successfully')
      }
      return responseGet;
    } catch (error) {
      CustomToast.error('Error while update course data')
        return error;
    } 
  };
  


  
  