import React from 'react'
import { Outlet } from 'react-router-dom'
import NoSidebar from '../layouts/nosidebar'

const CourseLayout = ({ children }) => {
  return (
    <>
      <NoSidebar children={children}>
        <Outlet />
      </NoSidebar>
    </>
  )
}

export default CourseLayout;