import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom'
import { fetchCourseData} from '../../../service/course';
import CustomToast from '../../Toast';

const Courses = () => {
    const [course, setcourse] = useState({});
    const [search, setSearch] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        getCourses()
    }, [])

    const getCourses = async () => {
        try {
            const course = await fetchCourseData(search);
            if (course.status) {
                setcourse(course.data);
                
            } else {
                console.error("Error fetching courses:", course.error);
                CustomToast.error('Error while fetching courses')
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
            CustomToast.error('Error while fetching courses')
        }
    };
    const handleSearch=(e)=>{
        setSearch(e.target.value)
        getCourses();
    }

    const dateFormat = (dateString) => {
        // Convert the date string to a Date object
        const dateObject = new Date(dateString);
        // Format the date as dd-mm-yyyy
        const formattedDate = dateObject.toLocaleDateString('en-GB'); // 'en-GB' for dd-mm-yyyy format
        return formattedDate
    }

    const editCourse = async(courseId,courseName,data)=>{
        await sessionStorage.setItem("courseId",courseId)
        await sessionStorage.setItem("courseName",courseName)
        await sessionStorage.setItem("selectedLanguages",JSON.stringify([{"value":data?.course_meta_id,"label":data?.lang}]))
        navigate('/course_details');
    }

    return (
        <div className='page__main'>
            <div className="dashboard">
                <div className='row mb-4'>
                    <div className='col-md-4'>
                        <div className="card-box">
                            <p className='mb-0 d-flex'>
                                <div className='course_count'>{course.total_courses || 0}</div>
                                <div className="right-border"></div>
                                <div className='courses_create'>Courses Created</div>
                            </p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="card-box">
                            <p className='mb-0 d-flex'>
                                <div className='course_count'>{course.published_courses || 0}</div>
                                <div className="right-border"></div>
                                <div className='courses_create'>Courses Published</div>
                            </p>

                        </div>
                    </div>
                    <div className='col-md-4 text-end'>
                        <a href="/create_course" className='btn btn-primary w-75'>Create Course</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">

                        <div className="d-flex justify-content-between align-items-center">
                            <div className='row g-3'>
                                <div className="col-auto">
                                    <label for="inputPassword6" className="col-form-label"><b>Courses</b></label>
                                </div>
                                <div className="col-auto">
                                    <div className="form-group has-search">
                                        <span className="form-control-feedback"><img src="/images/search.png" /></span>
                                        <input type="text" value={search} onChange={handleSearch} className="form-control" placeholder="Search Course" />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-0">
                                <p className="d-flex justify-content-between align-items-center mb-0 mr-10"><span className='circle-status green'></span> Published</p>
                                <p className="d-flex justify-content-between align-items-center mb-0 mr-10"><span className='circle-status pink'></span> Unpublished</p>
                                <p className="d-flex justify-content-between align-items-center mb-0 mr-10"><span className='circle-status yellow'></span> Draft</p>
                            </div>
                        </div>

                    </div>
                </div>
                <table className="table border-table">
                    <thead className='thead-dark px-3'>
                        <tr>
                            <th className='w-25 p-3'>Course Name</th>
                            <th className='w-25 p-3'>Created By</th>
                            <th className='w-25 p-3'>Published on</th>
                            <th className='w-25 p-3'>Last Update On</th>
                            <th className='w-50 p-3 text-center'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        {course&& course?.results?.map((item) => (
                            <tr key={item?.course_id} >
                                <td className='col px-3'>{item?.course_name || '-'}</td>
                                <td className='col px-3'>{item?.created_by || '-'}</td>
                                <td className='col px-3'>{item?.published_date != null ? dateFormat(item?.published_date) || '-' : '-'}</td>
                                <td className='col px-3'>{dateFormat(item?.updatedAt) || '-'}</td>
                                <td className='col px-3'>
                                {item && item.courses?.map((data) => (
                                    <a style={{cursor:"pointer"}} onClick={()=>editCourse(data?.course_meta_id,item?.course_name,data)} className={`status-btn ${data.lang === "unpublished" ? 'btn-pink' : data.status === "draft" ? 'btn-yellow' : 'btn-green'}`}>{data?.lang || '-'}</a>        
                                ))}
                                </td>
                            </tr> ))}
                    </tbody>
                  
                </table>
                {course&&course.results?.length==0?(<h3 style={{paddingLeft:"40%", paddingTop:"10%"}}>No Data Found</h3>):null}
            </div>
        </div>
    )
}

export default Courses;