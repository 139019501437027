import React from 'react';

const Dashboard = () => {
    return (
        <div className='page__main'>
            <div className="dashboard">
                
                  <h2>Comming Soon</h2>  

            </div>
        </div>
    )

}

export default Dashboard;