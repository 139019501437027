import React, { useEffect, useState } from 'react';
import '../shared/public.css'
import { fetchCourseDetail, updateCourseDetail } from '../../service/course';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CourseContent from '../pages/courseContent/courseContent';

const NoSidebar = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [courseDetail, setcourseDetail] = useState({})
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCourseDetail = sessionStorage.getItem("courseDetail");
            const selectedLanguages = JSON.parse(sessionStorage.getItem("selectedLanguages"));
            setSelectedCourse(selectedLanguages[0]?.value)
            if (newCourseDetail !== JSON.stringify(courseDetail)) {
                if(newCourseDetail)
               setcourseDetail(JSON.parse(newCourseDetail));
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [courseDetail]);

    const handleSelectChange = async(event) => {
        const selectedValue = event?.target?.value;
        await sessionStorage.setItem("selectedLanguages",JSON.stringify([{"value":selectedValue,"label":''}]))
        await sessionStorage.setItem("courseId",selectedValue)
        setSelectedCourse(selectedValue);
        fetchCourseDetail(selectedValue);
    };
    const updateCourseDataUnpublish = async(type, id)=>{
        let data={is_published:false}
        // let courseId=sessionStorage.getItem(courseId)
        await updateCourseDetail(type, id, data);
    }


    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setIsDisabled(false); // Enable the button after 20 seconds
        }, 20000); // 20 seconds in milliseconds
    
        return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
      }, [isDisabled]);
      
    const updateCourseData = async (type, id) => {
        if(type=="Publish"){
            let data={is_published:true}
            // let courseId=sessionStorage.getItem(courseId)
            await updateCourseDetail(type, id, data);
        } else {
            if (window.location.pathname == "/course_content") {
                window.submitForm(type) 
            } else {
                setIsDisabled(true);
                let image
                let video
                let imageB
                let id = await sessionStorage.getItem("courseId")
                let courseData = JSON.parse(sessionStorage.getItem("courseData"))
                if (sessionStorage.getItem("imageData"))
                    image = JSON.parse(sessionStorage.getItem("imageData"))
                if (sessionStorage.getItem("videoData"))
                    video = JSON.parse(sessionStorage.getItem("videoData"))
                if(sessionStorage.getItem("imageDataB")){
                    imageB= JSON.parse(sessionStorage.getItem("imageDataB"))
                }
                let data = {
                    duration:courseData.duration,
                    tagline: courseData.courseTagline,
                    "image": image,
                    "banner_image":imageB,
                    "video": video,
                    "contents": courseData.additionalEditors,
                    "title": courseData.courseTitle,
                    "skillset": courseData.skillset
                }
                await updateCourseDetail(type, id, data);
                sessionStorage.removeItem("imageData");
                sessionStorage.removeItem("videoData");
                navigate('/course_content');
            }
        }
    }

    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <button
                            data-mdb-collapse-init
                            className="navbar-toggler"
                            type="button"
                            data-mdb-target="#navbarExample01"
                            aria-controls="navbarExample01"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarExample01">

                            <div className="d-flex justify-content-between w-100 align-items-center mb-0">
                                <h5 className='mb-0 primary_color'>
                                    <ul className='d-flex justify-content-between w-100 align-items-center mb-0'>
                                        <li><a href="/courses"><img src="/images/arrow.png" className='pe-4'></img></a></li>
                                        <li>
                                            <Link to="/course_details" className={location.pathname === '/course_details' ? 'active' : ''}>
                                                Course Details
                                            </Link>
                                        </li>
                                        <li className='menu_vertical'></li>
                                        <li>
                                            <Link to="/course_content" className={location.pathname === '/course_content' ? 'active' : ''}>
                                                Course Content
                                            </Link>
                                        </li>
                                    </ul>
                                </h5>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className='menu_vertical'></div>
                                    <select className="form-select pe-5" value={selectedCourse} onChange={handleSelectChange}>
                                        {courseDetail && courseDetail?.courses?.map((data) => {
                                            return <option value={data?.course_meta_id}>{data?.lang}</option>
                                        })}
                                    </select>

                                    <button className='text-decoration-none font-18 black_color btn btn-link ms-4' onClick={()=>updateCourseData('save')} disabled={isDisabled}>Save</button>
                                    <a href="" className='text-decoration-none primary_color font-18 mlr-40'>Preview</a>
                                    {courseDetail&& !courseDetail?.is_published? <button className='btn btn-primary md-btn mr-10 d-flex justify-content-between align-items-center mb-0' onClick={()=>updateCourseData('Publish',selectedCourse)}>Publish</button>
                                    :<button className='btn btn-primary md-btn mr-10 d-flex justify-content-between align-items-center mb-0' onClick={()=>updateCourseDataUnpublish('Draft', selectedCourse)}>Draft</button>}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <main className={` ${show ? "add_body_padding" : "main"} `}>
                    {children}
                </main>
            </header>
        </>

    )

}

export default NoSidebar;
