// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course_details_sidebar {
    border-right: 1px solid rgba(102, 102, 102, 0.93);
    width: 350px;
    position: fixed;
    height: 100vh;
    padding: 60px 40px 0px;
}

.course_main {
    padding: 60px 35px 20px 35px;
    margin-left: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/courseDetails/courseDetails.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,YAAY;IACZ,eAAe;IACf,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;AACtB","sourcesContent":[".course_details_sidebar {\n    border-right: 1px solid rgba(102, 102, 102, 0.93);\n    width: 350px;\n    position: fixed;\n    height: 100vh;\n    padding: 60px 40px 0px;\n}\n\n.course_main {\n    padding: 60px 35px 20px 35px;\n    margin-left: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
