
import { baseUrl } from "../constants";
import apiFunctions from "./axiosConfig/apiFunctions";

 export const fetchLanguagesData = async () => {
    try {
      // GET request
      const responseGet = await apiFunctions.fetchData(`${baseUrl}getLangList`);
      return responseGet;
    } catch (error) {
        return error;
    } 
  };