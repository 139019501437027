import { baseUrl } from "../constants";
import apiFunctions from "./axiosConfig/apiFunctions";
import CustomToast from "../components/Toast";

export const updateModules = async (putData) => {
    try {
      // PUT request
      const responseGet = await apiFunctions.putData(`${baseUrl}updateModules`,putData);
      sessionStorage.setItem("courseMuduleDetail",JSON.stringify(responseGet.data))
      return responseGet;
    } catch (error) {
        return error;
    } 
  };

  export const fetchCourseModuleList = async (id) => {
    try {
      // GET request
      const responseGet = await apiFunctions.fetchData(`${baseUrl}getCourseModuleList/${id}`);
      return responseGet;
    } catch (error) {
        return error;
    } 
  };

  export const updateModuleContent = async (type, id, putData) => {
    try {
      // PUT request
      const responseGet = await apiFunctions.putData(`${baseUrl}updateModuleContent/${id}`,putData);
      CustomToast.success('Data save successfully')
      sessionStorage.setItem("courseModuleDetail",JSON.stringify(responseGet.data));

      return responseGet;
    } catch (error) {
        CustomToast.error('Please enter data')

        return error;
    } 
  };

  export const fetchModuleContent = async (id) => {
    try {
      // GET request
      const responseGet = await apiFunctions.fetchData(`${baseUrl}getModuleContent/${id}`);
      return responseGet;
    } catch (error) {
        return error;
    } 
  };

  export const deleteModuleContent = async (id) => {
    try {
      // GET request
      const responseGet = await apiFunctions.deleteData(`${baseUrl}deleteModule/${id}`);
      return responseGet;
    } catch (error) {
        return error;
    } 
  };
  
  