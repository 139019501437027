
// apiFunctions.js
import apiService from '../apiService';
import axiosInstance from './axiosConfig';

// Example API functions
const apiFunctions = {
  fetchData: async (url) => {
    try {
      const response = await axiosInstance.get(url, {headers: {
        token: `${apiService.getAuthToken()}`,
        "app-key": "DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166"
      }},
        );
      return response.data;
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Invalid token id!") {
        // Redirect the user to the login page
        window.location.href = '/';
      }
      
      // Handle specific error cases if needed
      throw error;
    }
  },

  postData: async (url,postData) => {
    try {
      const tokenValue = apiService.getAuthToken()
      const response = await axiosInstance.post(url, postData, {
        headers: {
          token: `${tokenValue}`,
          "app-key": "DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166"
        }
      });
      return response.data;
    } catch (error) {
      // Handle specific error cases if needed
      throw error;
    }
  },

  putData: async (url, putData) => {
    try {
      const response = await axiosInstance.put(url, putData, {
        headers: {
          token: `${apiService.getAuthToken()}`,
          "app-key": "DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166"
        }
      });
      return response.data;
    } catch (error) {
      // Handle specific error cases if needed
      throw error;
    }
  },

  deleteData: async (url) => {
    try {
      const response = await axiosInstance.delete(url, {
        headers: {
          token: `${apiService.getAuthToken()}`,
          "app-key": "DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166"
        }
      });
      return response.data;
    } catch (error) {
      // Handle specific error cases if needed
      throw error;
    }
  },

    // function to upload an image
    uploadImage: async (url,imageFile) => {
      try {
        const formData = new FormData();
        formData.append('image', imageFile);
  
        const response = await axiosInstance.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: `${apiService.getAuthToken()}`,
            "app-key": "DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166"
          },
        });
  
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    // function to upload an video
    uploadVideo: async (url,videoFile) => {
      try {
        const formData = new FormData();
        formData.append('video', videoFile);
  
        const response = await axiosInstance.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: `${apiService.getAuthToken()}`,
            "app-key": "DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166"
          },
        });
  
        return response.data;
      } catch (error) {
        throw error;
      }
    },
};

export default apiFunctions;
