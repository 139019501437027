// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    display: flex;
    position: relative;
    background-color: var(--bs-body-second) !important;
    height: 100vh;
}

.wrapper .sidebar {
    width: 350px;
    height: 100%;
    padding: 20px 45px 0px;
    position: fixed;
    top: 0;
    border-right: 2px solid #E2E2E2;
    background: #FDFDFD;
    z-index: 9999;
}

.wrapper .sidebar .navbar_logo {
    width: 220px;
    height: 82px;
    margin-bottom: 20px;
}

.wrapper .main_content {
    width: 100%;
    margin-left: 350px;
}

.wrapper .main_content .header {
    background: #fff;
    border: 1px solid #F7F6FB;
    height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,kDAAkD;IAClD,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,MAAM;IACN,+BAA+B;IAC/B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".wrapper {\n    display: flex;\n    position: relative;\n    background-color: var(--bs-body-second) !important;\n    height: 100vh;\n}\n\n.wrapper .sidebar {\n    width: 350px;\n    height: 100%;\n    padding: 20px 45px 0px;\n    position: fixed;\n    top: 0;\n    border-right: 2px solid #E2E2E2;\n    background: #FDFDFD;\n    z-index: 9999;\n}\n\n.wrapper .sidebar .navbar_logo {\n    width: 220px;\n    height: 82px;\n    margin-bottom: 20px;\n}\n\n.wrapper .main_content {\n    width: 100%;\n    margin-left: 350px;\n}\n\n.wrapper .main_content .header {\n    background: #fff;\n    border: 1px solid #F7F6FB;\n    height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
