import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../layouts/header'
import { saveCourseWithLang } from '../../service/course'

const WithoutPrivateLayout = ({ children }) => {
  const navigate = useNavigate();
  const createCourseWithLang = async() => {

    let selectedLanguagesArray = await sessionStorage.getItem("selectedLanguages")
    const lngs = JSON.parse(selectedLanguagesArray)?.map(item => ({
      id: item.value,
      lang: item.label
    }));
    let data = {
      lngs: lngs,
      name: sessionStorage.getItem("courseName")
    }
    if ((data.lngs && data.name) && data.lngs?.length >= 1) {
      await saveCourseWithLang(data)
      navigate('/course_details');
    }
    else {
      alert("please fill the form")
    }
  }

  useEffect(() => {
    sessionStorage.removeItem("selectedLanguages")
    sessionStorage.removeItem("courseName")
  }, [])
  return (
    <>
      <Header children={children} createCourseWithLang={createCourseWithLang} >
        <Outlet />
      </Header>
    </>
  )
}

export default WithoutPrivateLayout;