import Routes from "./appRoutes";
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <>
    <ToastContainer />
    <Routes/>
    </>
  );
}

export default App;