
import React, { useState, useRef, useEffect } from 'react';
import apiService from '../../service/apiService';
import OtpInput from 'react18-input-otp';
import { useNavigate } from 'react-router-dom';
import CustomToast from '../Toast';

const Login = ({ onVerifyOTP, onResendOTP }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otpTimer, setOtpTimer] = useState(120);
  const [uid, setUID] = useState('');
  const navigate = useNavigate();

  const recaptchaRef = useRef(null);

  const handleOTPChange = (e) => {
    const otpValue = e.target.value;
    setOTP(otpValue);
    setError('');
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(email);
    if (!isValid) {
      setEmailError('Please enter a valid email address.');
      // CustomToast.error('Please enter a valid email address.')
    } else {
      setEmailError('');
    }
  };


  const handleVerifyOTP = async () => {
    if (otp.length === 6 && /^\d+$/.test(otp)) {
      try {
        const obj = {
          uid,
          code:otp
        }
        const result = await apiService.post('verify-otp', obj);
        if (result && result.data && result.data.roles && (result.data.roles.includes('JetAdmin')) || result.data.roles.includes('Admin')) {
          apiService.setAuthToken(result.data.token)
          setShowOTP(false);
          navigate(`/courses`);
        } else {
         CustomToast.info("Unauthorized access: You do not have permission to view this page.")
         setTimeout(() => {
          window.location.reload(true);
         }, 1000);
        }
      } catch (error) {
        console.error('Error verifying OTP:', error.message);
        setError('Internal server error. Please try again later.');
        CustomToast.error('Failed to verify OTP. Please try again.')
        //CustomToast.error('Internal server error. Please try again later.') 
      }
    } else {
      setError('Please enter a valid 6-digit OTP.');
      CustomToast.error('Failed to verify OTP. Please try again.')
      //CustomToast.error('Please enter a valid 6-digit OTP.') 
    }
  };

  const handleResendOTP = async () => {
    try {
      setShowOTP(false);
      const result = await apiService.post('login-otp', { email, did: 'android 123', dname: 'redmeA1' });
      if (result.status === 200) {
        setOtpTimer(120); // Reset the timer
        setShowOTP(true);
        onResendOTP(); // Call parent component callback for successful OTP resend
      } else {
        setError('Failed to resend OTP. Please try again.');
        //CustomToast.error(error) 
      }
    } catch (error) {
      console.error('Error resending OTP:', error.message);
      setError('Internal server error. Please try again later.');
      //CustomToast.error(error) 
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Send OTP
      const otpResult = await apiService.login('login-otp', { email, did: 'android 123', dname: 'redmeA1' });
      if (otpResult.message == "OTP Generated!") {
        setUID(otpResult.uid)
        setShowOTP(true);
      } else {
       // alert('Failed to send OTP. Please try again.');
        CustomToast.error('Failed to send OTP. Please try again') 
      }
    } catch (error) {
      console.error('Error during login:', error.message);
     // alert('Internal server error. Please try again later.');
       CustomToast.error('Internal server error. Please try again later.');
    }
  };

  useEffect(() => {
    let timerId;

    if (showOTP && otpTimer > 0) {
      timerId = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (otpTimer === 0) {
      clearInterval(timerId);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [showOTP, otpTimer]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  return (
    
      <div className="login-overlay text-center bg-white w-100 m-auto p-4">
      <div className='login-card'>
        <div className="w-50 m-auto">
          <img src="/images/logo.svg" alt="logo" className="w-100" />
        </div>
        <hr/>
        <p className="fw-bold font-20 mb-3">Sign In</p>
          {showOTP ? (
            <>
              <p className="text-start mt-4">OTP sent to {email}</p>
              <div className="otp-input mb-2">
              <OtpInput
                value={otp}
                onChange={(otp) => handleOTPChange({ target: { value: otp } })}
                numInputs={6}
                inputStyle={{
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  width: '45px',
                  height: '45px',
                  fontSize: '20px',
                  color: '#000',
                  fontWeight: '400',
                  caretColor: 'black',
                  margin: '10px',
                  outline: 'none',
                }}
              />
              </div>

              <div className='d-flex justify-content-center align-items-center mb-2'>
              <button
              type="button"
              className="btn btn-link primary_color text-decoration-none text-dark-grey px-2"
              onClick={handleResendOTP}
              disabled={otpTimer > 0}
            >
              You can resend OTP in
            </button>

              <div className="otp-timer">{formatTime(otpTimer)}</div>
              </div>

              <button
                type="button"
                className="btn btn-md btn-primary w-100 mt-2 mb-2"
                onClick={handleVerifyOTP}
              >
                Verify OTP
              </button>
          
            </>
          ):(<form onSubmit={handleLogin}>
            <input
              type="text"
              value={email}
              placeholder="Email ID"
              className="form-control"
              onChange={handleEmailChange}
              required
            />
 
            <p className="text-start">{emailError && <div className="text-danger">{emailError}</div>}</p>

            <div className="d-flex justify-content-center flex-column align-items-center mt-4">
              <button
                type="button"
                className="btn btn-md btn-primary w-100 mb-2"
                onClick={handleLogin}
              >
                Sign In
              </button>
            </div>
          </form>)}
      </div>
      </div>
    
  )
}

export default Login;
