import React, { useState } from 'react';
import Menu from "./menu";
import '../shared/dashboard.css';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ children }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const SidebarData = [
        {
            //title: "Dashboard",
           // path: "/dashboard",
           // icon: "/images/dashboard.svg"
        },
        {
            title: "Courses",
            path: "/courses",
            icon: "/images/calendar.svg"
        }
    ]

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('authToken');
        // Redirect to the original website
        navigate(`/`);
    };

    return (
        <>
            <div className="wrapper">
                <aside className={`sidebar ${show ? "review" : " "} `} id="admin-dash-nav">
                    <a href='/'> <img src="/images/logo.svg" alt="logo" className="navbar_logo" /></a>
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div className='sidebar-menu'>
                            {SidebarData.map((item, index) => {
                                return <Menu item={item} key={index} />;
                            })}
                        </div>
                    </div>
                </aside>
                <div className="main_content">
                    <div className="header fixed-top d-flex justify-content-end align-items-center">
                        <div className='dropdown me-4'>
                        <a href='#' className='text-decoration-none dropbtn' > <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="url(#paint0_linear_2329_1139)" />
                            <path d="M30.9694 36.6562H17.0307C16.1753 36.6562 15.3551 36.3165 14.7502 35.7117C14.1454 35.1069 13.8057 34.2866 13.8057 33.4313V31.2562C13.8067 29.232 14.6115 27.2909 16.0432 25.8599C17.475 24.4289 19.4164 23.625 21.4407 23.625H26.5594C28.5842 23.625 30.526 24.4293 31.9578 25.8609C33.3897 27.2925 34.1942 29.2343 34.1944 31.2591V33.4341C34.1937 34.2889 33.8536 35.1085 33.2488 35.7127C32.6441 36.3169 31.8243 36.6562 30.9694 36.6562Z" fill="white" />
                            <path d="M24 22.2056C26.9995 22.2056 29.431 19.7741 29.431 16.7747C29.431 13.7753 26.9995 11.3438 24 11.3438C21.0006 11.3438 18.5691 13.7753 18.5691 16.7747C18.5691 19.7741 21.0006 22.2056 24 22.2056Z" fill="white" />
                            <defs>
                                <linearGradient id="paint0_linear_2329_1139" x1="7.02937" y1="7.02937" x2="40.9706" y2="40.9706" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#9E9E9E" />
                                    <stop offset="1" stopColor="#222222" />
                                </linearGradient>
                            </defs>
                        </svg></a>
                        <ul className="dropdown-content text-small" >
                        {/* <li>
                            <a className="dropdown-item" href="/">
                                Profile
                            </a>
                        </li> */}
                        <li>
                            <a className="dropdown-item" href="/">
                            <button className='btn btn-link text-white text-decoration-none' onClick={handleLogout}>Logout</button>
                            </a>
                        </li>
                    </ul>
                       </div>
                    </div>
                    <main className={` ${show ? "add_body_padding" : "main"} `}>
                        {children}
                    </main>
                </div>
            </div>
        </>
    )


}

export default Navbar