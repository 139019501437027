import React, {useState } from 'react';
import '../shared/public.css'

const Header = ({ children,createCourseWithLang }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <div className='course_wrapper'>
                <header>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <button
                                data-mdb-collapse-init
                                className="navbar-toggler"
                                type="button"
                                data-mdb-target="#navbarExample01"
                                aria-controls="navbarExample01"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fas fa-bars"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarExample01">

                                <div className="d-flex justify-content-between w-100 align-items-center mb-0">
                                    <h5 className='mb-0 primary_color'><a href="/courses"><img src="/images/arrow.png" className='p-40'></img></a>Create Course</h5>
                                    <button className='btn btn-primary md-btn mr-10 d-flex justify-content-between align-items-center mb-0' onClick={createCourseWithLang}>Next</button>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <main className={` ${show ? "add_body_padding" : "main"} `}>
                        {children}
                    </main>
                </header>
            </div>
        </>

    )
}

export default Header;